import React from 'react';
import styled from 'styled-components';
import Layout from "../components/layout";
import SectionFooter from '../components/sections/section-footer/SectionFooter';
import { theme } from "../utils/theme"

import Point from '../components/points/point';


const Container = styled.div`
  /* height: 100vh; */
  width: 100%;
  background-color: ${props => props.theme.colors.dark};
  overflow-y: visible;
`

const TitleCond = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  width: 200px;
  line-height: 1.1;
  color: ${props => props.theme.colors.body};
  margin-bottom: 150px;
  text-align: left;

  @media ${props => props.theme.breaks.down('md')} {
    width: 50%;
    max-width: 300px;
    font-size: 2rem;
    margin: 5rem 0 10rem 0;
  }
`

const PointSection = styled.div`
  height: 100vh;
  padding-top: 50px;
  text-align: center;
  padding-left: 80px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;

    .title-cond {
      width: 90% !important;
    }
  }
`

const PointContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  margin-top: 7rem;
  text-align: left;
  position: relative;

  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media ${props => props.theme.breaks.down('lg')} {
    width: 80%;
  }


  @media ${props => props.theme.breaks.down('md')} {
    width: 90%;
  }
`

const Exclam = styled.h5`
  font-size: 3rem;
  font-weight: 300;
  color: ${props => props.theme.colors.body};
`

const FixedFooter = styled.div`
  width: 100%;
  /* min-width: ${props => props.theme.maxWidthMd};
  max-width: 1400px; */
  position: fixed;
  /* left: 50%;
  transform: translateX(-50%); */
  height: 100px;
  padding-left: 40px;
  bottom: 20px;

  @media ${props => props.theme.breaks.down('md')} {
    padding-left: 0;
    bottom: 0;
  }
`


const HowWeWorkPage = ({ data }) => {


  // const {HeroImage, Heading1, Heading2} = data.strapiHero;
  return (
    
      <Layout color={theme.colors.body} pageTitle="How we work">
      <Container>
        
        <PointSection className="point-sec">
          <div className="title-cond" style={{width: '70%', margin: '0 auto'}}>
          <TitleCond>How much work is this going to be for me? </TitleCond>
          </div>
          <PointContainer>
            <Point num='01'>We meet.</Point>
            <Point num='02'>We take a little bit of time to ask you some questions we need you to answer about your branding project.
              <br /><br />
            (When the meeting’s over, we talk to you as much as you want us to.).</Point>
            <Point num='03'>We meet again to see how happy you are with your branding project.
              <div style={{position: 'absolute', left: '5%', bottom: 0}}>
              <Exclam>THAT’S IT. </Exclam>
              </div>
            </Point>
            
          </PointContainer>
          
        </PointSection>

         <FixedFooter>
          <SectionFooter color={theme.colors.body}></SectionFooter>
          
         </FixedFooter>
        
      </Container>
      </Layout>
    
)
}

export default HowWeWorkPage;