import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
 width: 33%;
 min-width: 30rem;
 position: relative;
`

const Number = styled.div`
  color: ${props => props.theme.colors.primary};
  height: 37px;
  width: 37px;
  border-radius: 50%;
  text-align: center;
  padding: 8px 0;
  font-weight: 300;
  font-size: 1.5rem;
  border: 2px solid  ${props => props.theme.colors.primary};
  margin-bottom: 2rem;
`

const Content = styled.p`
  color: ${props => props.theme.colors.primary};
  font-weight: 300;
  font-size: 2.5rem;
  width: 70%;
`

export default ({num, children}) => {
 
 return (
  <Container>
    <Number>{num}</Number>
    <Content>{children}</Content>
  </Container>
 )
}